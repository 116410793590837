<template>
<div>

</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "partnerDash",
  data() {
    return {
      entity : {
        businesses : []
      },
      headers : [
        { value : 'key' , width : 50 },
        { value : 'value' }
      ],
      businessesItems : []
    }
  },
  computed : {
    businessHeaders() {
      return [
        { text : this.$t('Date') , value : 'date' , width : 50 },
        { text : this.$t('Name') , value : 'name'  },
        { text : this.$t('PaymentPlan') , value : 'payment_plan' , width : 50  },
        { text : this.$t('Payments') , value : 'payments'  , width : 50 },
      ]
    },
    statsItems() {
      return [
        { key : this.$t('TotalEarned')    , value : `${this.entity.total_earn || 0} $` },
        { key : this.$t('ExpectedEarn')   , value : `${this.entity.expected_earn || 0} $` },
        { key : this.$t('Businesses')     , value : `${this.entity.completed_businesses_count || 0}/${this.entity.businesses_count || 0}` },
        { key : this.$t('Clicks')         , value : this.entity.clicks || 0 }
      ]
    },
    navigationSelect() {
      return [
        {
          name : this.$t('Statistics') ,
          subtitle : this.$t('Statistics') ,
          value : 'stats',
          icon : 'mdi-cash-multiple',
          expanded : true,
        },
        {
          name : this.$t('Registrations') ,
          subtitle : this.$t('Registrations') ,
          icon : 'mdi-school-outline',
          value : 'businesses',
        },
      ]
    },
  },
  methods : {
    ...mapActions('adminPartners', [
      'GET_PARTNER_DATA_SELF',
    ]),

    //technical
    async initPage() {
      let result = await this.GET_PARTNER_DATA_SELF()

      if ( !result ) {
        return this.ERROR()
      }

      this.entity = result

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>